import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

Sentry.init({
  dsn: 'https://5948ecb18a55aeee499e81c728ad9285@o202054.ingest.sentry.io'
    + '/4506218350641152',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost'],
    }),
    new Sentry.Replay(),
  ],
  environment: process.env.NODE_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const wrapPageElement = ({ element }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <AnimatePresence mode="wait" initial={false}>
    {element}
  </AnimatePresence>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms
  const TRANSITION_DELAY = 0.3 * 1000 * 2;
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
  } else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0];
    // eslint-disable-next-line max-len
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY);
  }
  return false;
};
